//
//
//
//
//
//
//

import TheGame from '~/components/TheGame'
import TheCheat from '~/components/TheCheat'
import {mapGetters} from "vuex"

export default {
  name: "slug",
  auth: false,
  components: {TheGame, TheCheat},
  computed: {
    ...mapGetters({games: 'getGames'})
  },
  head() {
    return {
      link: [
        {rel: 'icon', type: 'image/svg+xml', href: '/favicon-120.svg'}
      ],
    }
  },
  beforeMount() {
    document.documentElement.lang = (this.$i18n.locale === 'ru') ? 'ru' : 'en-US';
  },
  async asyncData({params, $axios, error}) {
    try {
      const game = await $axios.$get(process.env.APP_URL + '/api/games/' + params.game).then((res) => {
        if (Array.isArray(res)) {
          return res[0]
        } else {
          return res
        }
      })
      const cheat = game.cheats.filter((cheat) => (params.cheat === cheat.url_path));

      if (params.cheat !== undefined && Array.isArray(cheat) && cheat.length === 0) {
        error({statusCode: 404, message: 'Page not found'})
      }

      return {route: params.game, game: game, cheat: cheat.length === 0 ? undefined : cheat[0]}
    } catch (e) {
      error({statusCode: 404, message: 'Page not found'})
    }
  }

}
