//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PostsList from "@/components/admin/posts/PostsList";
import CreatePostForm from "@/components/admin/posts/CreatePostForm";

export default {
  name: "posts",
  components: {PostsList, CreatePostForm},
  layout: 'admin',
  data() {
    return {
      editable: false
    }
  },
  created() {
    this.$nuxt.$on('edit-post', (id) => {
      this.editable = true;
    })
  },
  head() {
    return {
      title: 'Посты'
    }
  }
}
