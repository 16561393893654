//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PagesList from "@/components/admin/pages/PagesList";
import CreatePageForm from "@/components/admin/pages/CreatePageForm";


export default {
  name: "pages",
  components: {PagesList, CreatePageForm},
  layout: 'admin',
  data() {
    return {
      editable: false
    }
  },
  created() {
    this.$nuxt.$on('edit-page', (id) => {
      this.editable = true;
    })
  },
  head() {
    return {
      title: 'Страницы'
    }
  }
}
