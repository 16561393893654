//
//
//
//
//
//
//
//
//
//
//
//
//

import CreateGame from "@/components/admin/games/CreateGame";
import GamesList from "@/components/admin/games/GamesList";

export default {
  name: "games",
  components: {GamesList, CreateGame},
  layout: 'admin',
  data() {
    return {
      editable: false
    }
  },
  created() {
    this.$nuxt.$on('game-edit', (id) => {
      this.editable = true;
    })
  },
  head() {
    return {
      title: "Игры"
    }
  },
}
