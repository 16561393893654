import { render, staticRenderFns } from "./_cheat.vue?vue&type=template&id=465525bf&scoped=true&"
import script from "./_cheat.vue?vue&type=script&lang=js&"
export * from "./_cheat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465525bf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheCheat: require('/var/www/www-root/data/www/wh-satano.ru/frontend/components/TheCheat.vue').default,TheGame: require('/var/www/www-root/data/www/wh-satano.ru/frontend/components/TheGame.vue').default})
