import { render, staticRenderFns } from "./index.vue?vue&type=template&id=44ccaa71&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=44ccaa71&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ccaa71",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Blog: require('/var/www/www-root/data/www/wh-satano.ru/frontend/components/blog/Blog.vue').default})
