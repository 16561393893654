//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CreateCheat from "@/components/admin/cheats/CreateCheat"
import CheatsList from "@/components/admin/cheats/CheatsList";

export default {
  name: "cheats",
  components: {CheatsList, CreateCheat},
  layout: 'admin',
  data() {
    return {
      editable: false
    }
  },
  created() {
    this.$nuxt.$on('edit-cheat', (id) => {
      this.editable = true;
    })
  },
  head() {
    return {
      title: 'Читы'
    }
  }
}
