//
//
//
//
//
//
//
//
//
//
//
//

import TheReviews from '../components/index_page/TheReviews'
import TheLastNews from "@/components/index_page/TheLastNews";
import TheLastCheats from "@/components/index_page/TheLastCheats";
import TheAdvantages from '../components/index_page/TheAdvantages'
import TheDescription from '../components/index_page/TheDescription'
import TheMainHeading from '../components/index_page/TheMainHeading'
import TheGameCategories from "~/components/index_page/TheGameCategories";
import TheSafeHacks from "../components/index_page/TheSafeHacks.vue";

export default {
  auth: false,
  components: {TheSafeHacks, TheGameCategories, TheLastNews, TheReviews, TheAdvantages, TheDescription, TheMainHeading, TheLastCheats},
  layout: 'default',
  beforeMount() {
    document.documentElement.lang = (this.$i18n.locale === 'ru') ? 'ru' : 'en-US';
  },
  head() {
    return {
      title: this.$i18n.t('index_page.title'),
      link: [
        {rel: 'icon', type: 'image/svg+xml', href: '/favicon-120.svg'}
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$i18n.t('index_page.description')
        },
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: ''},
        {name: 'format-detection', content: 'telephone=no'},
        {name: 'yandex-verification', content: '5f68c461d73d1f52'}
      ],
    }
  }

}
