//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from "@/components/ui/Breadcrumbs.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'about',
  components: {Breadcrumbs},
  computed: {
    ...mapGetters(['getPage']),
  },
  head() {
    const page = this.getPage('about', this.$i18n.locale);

    return {
      title: page['meta_title'],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: page['meta_description']
        },
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: ''},
        {name: 'format-detection', content: 'telephone=no'},
        {name: 'yandex-verification', content: '5f68c461d73d1f52'}
      ],
      link: [
        {rel: 'icon', type: 'image/svg+xml', href: '/favicon-120.svg'}
      ],
    }
  },
  beforeMount() {
    document.documentElement.lang = (this.$i18n.locale === 'ru') ? 'ru' : 'en-US';
  },
  data() {
    return {
      links: [
        {text: this.$i18n.t('about_page.main_website'), url: 'https://wh-satano.ru', icon_class: 'fas fa-home'},
        {
          text: this.$i18n.t('about_page.backup_website'),
          url: 'https://phoenix-hack.org',
          icon_class: 'fas fa-link'
        },
        {text: 'Sellix', url: 'https://phoenix-satano.sellix.io', icon_class: 'fas fa-shopping-cart'},
        {text: 'Telegram', url: 'https://t.me/phoenix_satano', icon_class: 'fab fa-telegram-plane'},
        {text: 'Telegram Legacy/Neon', url: 'https://t.me/devotionproj', icon_class: 'fab fa-telegram-plane'},
        {
          text: this.$i18n.t('about_page.main_vk_group'),
          url: 'https://vk.com/satanosoft',
          icon_class: 'fab fa-vk'
        },
        {text: 'RUST', url: 'https://vk.com/satano_rust', icon_class: 'fab fa-vk'},
        {text: 'LOL, VALORANT', url: 'https://vk.com/indigo_hack', icon_class: 'fab fa-vk'},
        {text: 'DBD, HUNT, BF V', url: 'https://vk.com/hunt_hack', icon_class: 'fab fa-vk'},
        {text: 'PUBG, APEX, RAINBOW', url: 'https://vk.com/satanobattlegrounds', icon_class: 'fab fa-vk'},
      ]
    }
  },
  auth: false,
  layout: 'default',

}
