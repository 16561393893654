//
//
//
//

import Login from '../../components/admin/Login'

export default {
  name: 'login',
  layout: 'admin',
  components: {Login},
  head() {
    return {
      title: 'Вход в панель администратора'
    }
  }
}
