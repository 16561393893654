//
//
//
//
//
//
//
//
//
//

import CategoriesList from "@/components/admin/categories/CategoriesList";
import CreateCategoryForm from "@/components/admin/categories/CreateCategoryForm";

export default {
  name: "categories",
  components: {CategoriesList, CreateCategoryForm},
  layout: 'admin',
  head() {
    return {
      title: 'Категории новостей'
    }
  }
}
