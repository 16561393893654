//
//
//
//

import PostFull from "@/components/blog/PostFull";

export default {
  name: "id",
  components: {PostFull},
  auth: false,
  beforeMount() {
    document.documentElement.lang = (this.$i18n.locale === 'ru') ? 'ru' : 'en-US';
  },
  data() {
    return {
      post_id: this.$route.params.id
    }
  },
  head() {
    return {
      link: [
        {rel: 'icon', type: 'image/svg+xml', href: '/favicon-120.svg'}
      ],
    }
  }
}
