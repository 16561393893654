//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContentEditor from "../../components/admin/ContentEditor.vue";

export default {
  name: "settings",
  components: { ContentEditor },
  layout: 'admin',
  data() {
    return {
      status: false,
      settings: {
        title: '',
        description: '',
        socials: '',
        footer: '',
      }
    }
  },
  head() {
    return {
      title: "Настройки",
    }
  },
  async created() {
    this.settings = await this.$axios.$get('/api/settings');

    //this.$set(this, 'settings', await this.$axios.$get('/api/settings'))

    this.status = true;

    this.$nuxt.$on('change-content', ({content, lang, id}) => {
      this.settings[id] = content;
    })
  },
  methods: {
    submit() {
      this.$axios.$post('/api/settings', this.settings).then(() => {
        this.$toast.success('Настройки сохранены')
      }).catch(() => {
        this.$toast.error('Ошибка при сохранении')
      })
    }
  }
}
