//
//
//
//
//
//
//
//
//
//

import FileList from "@/components/admin/files/FileList";
import FileAdditionZone from "@/components/admin/files/FileAdditionZone";

export default {
  name: "files",
  components: {FileList, FileAdditionZone},
  layout: 'admin',
  head() {
    return {
      title: "Файловый менеджер"
    }
  }
}
