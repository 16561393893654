//
//
//
//
//
//
//
//
//

import Breadcrumbs from "@/components/ui/Breadcrumbs.vue";
import Pagination from "@/components/ui/Pagination.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'blog',
  components: {Pagination, Breadcrumbs},
  auth: false,
  computed: {
    ...mapGetters(['getPage']),
  },
  beforeMount() {
    document.documentElement.lang = (this.$i18n.locale === 'ru') ? 'ru' : 'en-US';
  },
  head() {
    const page = this.getPage('blog', this.$i18n.locale);
    return {
      title: page['meta_title'],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: page['meta_description']
        },
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: ''},
        {name: 'format-detection', content: 'telephone=no'},
        {name: 'yandex-verification', content: '5f68c461d73d1f52'}
      ],

      link: [
        {rel: 'icon', type: 'image/svg+xml', href: '/favicon-120.svg'}
      ],

    }
  }

}
